export const Credito = () =>{
    return(
        <section>
        Assistencia
<a href="https://www.flaticon.com/br/icones-gratis/carro" title="carro ícones">Carro ícones criados por Smashicons - Flaticon</a>

Chave do Carro
<a href="https://www.flaticon.com/br/icones-gratis/carro" title="carro ícones">Carro ícones criados por Freepik - Flaticon</a>

Rastreamento
<a href="https://www.flaticon.com/br/icones-gratis/gps" title="gps ícones">Gps ícones criados por M Karruly - Flaticon</a>

Vidros
<a href="https://www.flaticon.com/free-icons/car-door" title="car door icons">Car door icons created by Smashicons - Flaticon</a>

Pane
<a href="https://www.flaticon.com/free-icons/breakdown" title="breakdown icons">Breakdown icons created by Freepik - Flaticon</a>

Terceiro
<a href="https://www.flaticon.com/free-icons/accident" title="accident icons">Accident icons created by itim2101 - Flaticon</a>

Reboque
<a href="https://www.flaticon.com/br/icones-gratis/caminhao-de-reboque" title="caminhão de reboque ícones">Caminhão de reboque ícones criados por itim2101 - Flaticon</a>

Fenomeno
<a href="https://www.flaticon.com/br/icones-gratis/inundar" title="inundar ícones">Inundar ícones criados por Ayub Irawan - Flaticon</a>

Sos mecanico
<a href="https://www.flaticon.com/br/icones-gratis/chave-inglesa" title="chave inglesa ícones">Chave inglesa ícones criados por mynamepong - Flaticon</a>

Incendio
<a href="https://www.flaticon.com/br/icones-gratis/acidente-de-carro" title="acidente de carro ícones">Acidente de carro ícones criados por Smashicons - Flaticon</a>

Furto Roubo
<a href="https://www.flaticon.com/br/icones-gratis/ladrao" title="ladrão ícones">Ladrão ícones criados por Freepik - Flaticon</a>

Assistencia 24 Horas
<a href="https://www.flaticon.com/br/icones-gratis/aberto-24-horas" title="aberto 24 horas ícones">Aberto 24 horas ícones criados por Smashicons - Flaticon</a>

Colisão
<a href="https://www.flaticon.com/br/icones-gratis/acidente-de-carro" title="acidente de carro ícones">Acidente de carro ícones criados por Good Ware - Flaticon</a>

Chaveiro
<a href="https://www.flaticon.com/free-icons/keys" title="keys icons">Keys icons created by Smashicons - Flaticon</a>

Telefone
<a href="https://www.flaticon.com/br/icones-gratis/ligar" title="ligar ícones">Ligar ícones criados por Gregor Cresnar - Flaticon</a>

MapaFooter
<a href="https://www.flaticon.com/br/icones-gratis/mapa" title="mapa ícones">Mapa ícones criados por Freepik - Flaticon</a>

Relogio
<a href="https://www.flaticon.com/br/icones-gratis/relogio" title="relógio ícones">Relógio ícones criados por Kharisma - Flaticon</a>

Instagram
<a href="https://www.flaticon.com/br/icones-gratis/instagram" title="instagram ícones">Instagram ícones criados por Freepik - Flaticon</a>

Whatsapp
<a href="https://www.flaticon.com/br/icones-gratis/whatsapp" title="whatsapp ícones">Whatsapp ícones criados por Swifticons - Flaticon</a>

Email
<a href="https://www.flaticon.com/br/icones-gratis/o-email" title="o email ícones">O email ícones criados por Grow studio - Flaticon</a>

TelefoneBanner
<a href="https://www.flaticon.com/br/icones-gratis/telefone" title="telefone ícones">Telefone ícones criados por Those Icons - Flaticon</a>

EmailBanner
<a href="https://www.flaticon.com/br/autores/pixel-perfect" title="Pixel perfect"> Pixel perfect </a> from <a href="https://www.flaticon.com/br/" title="Flaticon">www.flaticon.com'</a>
</section>    
)
}