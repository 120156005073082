import { Card, Flex } from "antd";
import "./style.css";
const { innerWidth: width, innerHeight: height } = window;

export const BannerAPP = () => {
  return (
    <Flex
        vertical={false}
        align="flex-start"
        justify="space-around"
      style={{
        width: "100%",
        height: width < 600 ? "100vh" : null,
        backgroundImage: `url(${require("../../assets/banner_app.jpg")})`,
        backgroundSize: "cover", // Garante que a imagem cubra todo o card
        backgroundPosition: "center", // Centraliza a imagem
        marginTop:"10vh"
      }}
    >
      <Flex vertical={true} align="center" justify="center" style={{width:"60%",marginTop:"10vh"}}>
        <h1 className="titulo_banner_app">Baixe nosso App e aproveite nossos beneficíos</h1>
        <button onClick={() => window.open('https://play.google.com/store/apps/details?id=com.samunogue.alianca&hl=pt_BR')} className='button-baixar-app'></button>
      </Flex>
      {width > 1100 &&(
        <Flex vertical={true} style={{width:"40%", marginBottom:"10vh"}}>
          <img src={require('../../assets/celular.png')} height={500} />
        </Flex>
      )}
    </Flex>
  );
};
